import { backOffice } from '@emobg/web-api-client';
import { createCustomStore, withScopes } from '@emobg/web-utils';
import { clearErrors, toastError } from '@/utils';

const {
  getService,
  postService,
  putService,
  deleteService,
} = backOffice.services;

export const scopes = {
  service: 'service',
  serviceForm: 'serviceForm',
  deleteService: 'deleteService',
};

const services = createCustomStore(({ runAsyncFlow }) => withScopes(scopes, ({
  state: {
    [scopes.service]: {
      data: null,
    },
  },
  mutations: {
    setError(state, payload) {
      toastError.bind(this)(state, payload, [scopes.deleteService]);
    },
    clearErrors,
  },
  actions: {
    async getService({ commit }, serviceId) {
      await runAsyncFlow(commit, {
        request: getService,
        params: [serviceId],
        scope: scopes.service,
      });
    },
    async postService({ commit }, data) {
      await runAsyncFlow(commit, {
        request: postService,
        params: [data],
        scope: scopes.serviceForm,
      });
    },
    async putService({ commit }, { serviceId, data }) {
      await runAsyncFlow(commit, {
        request: putService,
        params: [serviceId, data],
        scope: scopes.serviceForm,
      });
    },
    async deleteService({ commit }, serviceId) {
      await runAsyncFlow(commit, {
        request: deleteService,
        params: [serviceId],
        scope: scopes.deleteService,
      });
    },
  },
})));

export default services;
